<template>
  <div class="pic-wrap-container">
    <div class="pic-preview">
      <div class="pic-masking" v-if="uploading">上传中</div>
      <div class="pic-masking" v-if="cuting">制作中</div>
      <img v-if="cuted" :src="img.base64" alt="" />
    </div>
    <div class="pic-right-container">
      <div class="pic-name ellipsis">{{ img.name }}</div>
      <div class="pic-operator-container">
        <div class="pic-op-item" :class="{'pic-op-item-enable':cuted}">重命名</div>
        <div class="pic-op-item pic-op-preview-item" :class="{'pic-op-item-enable':cuted}">预览</div>
        <div
          class="pic-op-item" :class="{'pic-op-item-enable':canRemove}"
          @click="remove()"
        >
          移除
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  props: {
    img: {
      require: true,
    },
  },
  computed: {
    uploading() {
      return this.img.status == 0;
    },
    cuting() {
      return this.img.status == 1;
    },
    cuted() {
      return this.img.status == 2;
    },
    canRemove() {
      return this.cuting || this.cuted;
    },
  },
  methods: {
    ...mapActions("publishModule", ["removeImg"]),
    remove() {
      if (this.canRemove) this.removeImg(this.img.id);
    },
  },
};
</script>
<style lang="scss">
.pic-wrap-container {
  padding: 15px 20px;
  background: $color-background;
  display: flex;
  box-sizing: border-box;
  .pic-preview {
    width: 60px;
    min-width: 60px;
    margin-right: 15px;
    img {
      width: 100%;
    }
    .pic-masking {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #999999;
      color: #ffffff;
      font-size: $font-size-small-x;
    }
  }
  .pic-right-container {
    flex: 1;
    width: 1px;
    .pic-name {
      line-height: 30px;
      font-size: $font-size-small;
      color: $color-text;
    }
    .pic-operator-container {
      display: flex;
      line-height: 30px;
      font-size: $font-size-small-x;
      color: $color-theme;
      .pic-op-item {
        color: #999999;
      }
      .pic-op-item-enable {
        color: $color-theme;
        cursor: pointer;
      }
      .pic-op-preview-item {
        flex: 1;
        text-align: center;
      }
    }
  }
}
</style>