<template>
  <div class="main-pic-select-container">
    <div class="main-pic-select-top">
      <div class="main-pick-media">
        <pano-button name="从素材库添加" @click="selectMedia()"></pano-button>
      </div>
      <div class="main-pick-local">
        <pano-button name="从本地文件添加" @click="selectLocal()"></pano-button>
      </div>
      <div class="main-tip"><a href="">新手教程 2:1与六面体的区别与命名规则</a></div>
    </div>
    <div class="main-pic-select-body" v-if="hashImgs">
      <pic-wrap v-for="(img,index) in imgs" :key="index" :img="img" class="pic-item"></pic-wrap>
    </div>
    <div class="main-pic-select-no-body" v-else>
      展示还没有图片
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import PicWrap from '../pic-wrap'
export default {
  computed:{
    ...mapState('publishModule',['imgs']),
    hashImgs(){
      return this.imgs.length>0;
    }
  },
  methods:{
    selectMedia(){
      this.$emit('selectMedia');
    },
    selectLocal(){
      this.$emit('selectLocal');
    }
  },
  components: {
    PicWrap
  }
}
</script>
<style lang="scss">
.main-pic-select-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  .main-pic-select-top {
    padding-bottom: 20px;
    border-bottom: 1px solid #eeeeee;
    display: flex;
    .main-pick-media,
    .main-pick-local {
      margin-right: 20px;
      width: 150px;
    }
    .main-tip {
      flex: 1;
      text-align: right;
      line-height: 30px;
      a{
        color: $color-theme;
      }
    }
  }
  .main-pic-select-body{
    display: flex;
    flex-wrap: wrap;
    padding: 20px 0;
    .pic-item{
      width: calc(33% - 20px);
      margin-right: 20px;
      margin-bottom: 20px;
    }
  }
  .main-pic-select-no-body{
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    padding: 20px 0;
    align-items: center;
    justify-content: center;
  }
}
</style>