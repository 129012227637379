<template>
  <div class="publish-body-conatiner">
    <div v-if="!publishSucceed" class="publish-content-container">
      <div class="publish-left-container">
        <main-pic-select
          @selectMedia="selectMedia()"
          @selectLocal="selectLocal()"
        ></main-pic-select>
      </div>
      <div class="publish-right-container">
        <publish-product></publish-product>
      </div>
    </div>
    <div v-if="publishSucceed" class="publish-succeed-container">
      <div class="publish-succeed-tip">发布成功</div>
      <div class="publish-succeed-go">
        <pano-button name="前往编辑作品" @click="goEdit()"></pano-button>
      </div>
      <div class="publish-succeed-continue">继续发布作品</div>
    </div>
  </div>
</template>
<script>
import MainPicSelect from "./components/main-pic-select";
import PublishProduct from "./components/publish-product";
import CopyrightTip from "./components/copyright-tip";
import { mapActions } from "vuex";
import collect from "@/common/js/collect";
export default {
  data() {
    return {
      publishSucceed: true,
      queryQueue: [],
      startTimer: false,
      timer: null,
    };
  },
  mounted() {
    this.publishSucceed = false;
    this.timer = setInterval(() => {
      this.queryImgCutComplete();
    }, 5000);
  },
  beforeDestroy() {
    this.timer && clearInterval(this.timer);
  },
  computed: {},
  methods: {
    ...mapActions("publishModule", ["selectImg", "queryImageCutComplete"]),
    goEdit() {
      this.$router.push("/edit");
    },
    selectMedia() {
      console.log("selectMedia");
    },
    queryImgCutComplete() {
      if (this.queryQueue.length > 0) {
        this.queryImageCutComplete(this.queryQueue).then((o) => {
          for (let i = 0; i < o.length; i++) {
            collect.remove(this.queryQueue, (q) => q == o);
          }
        });
      }
    },
    // 打开本地添加图片选项
    selectLocal() {
      this._openCopyrightTipModal((o) => {
        // modal 回调选择图片
        o.forEach((item) => {
          this.selectImg(item)
            .then((serverId) => {
              this.queryQueue.push(serverId);
            })
            .catch((e) => alert(e));
        });
      });
    },
    _openCopyrightTipModal(callback) {
      this.$modal({
        template: CopyrightTip,
        close: (o) => {
          callback && callback(o);
        },
      });
    },
    startImgCutStatusTimer() {},
  },
  components: {
    MainPicSelect,
    PublishProduct,
  },
};
</script>
<style lang="scss">
.publish-body-conatiner {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
  align-items: center;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  .publish-content-container {
    width: 70%;
    min-width: 1180px;
    background: #ffffff;
    flex: 1;
    display: flex;
    .publish-left-container {
      flex: 1;
      padding: 20px;
    }
    .publish-right-container {
      border-left: 1px solid #eeeeee;
      width: 300px;
      padding: 20px;
    }
  }
  .publish-succeed-container {
    width: 70%;
    min-width: 1180px;
    background: #ffffff;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .publish-succeed-tip {
      font-size: $font-size-medium-x;
      margin-bottom: 20px;
    }
    .publish-succeed-go {
      margin-bottom: 10px;
    }
    .publish-succeed-continue {
      font-size: $font-size-small;
      color: $color-theme;
      margin-bottom: 10px;
      cursor: pointer;
    }
  }
}
</style>