<template>
  <div class="copyright-tip-container">
    <div class="copyright-tip-wrap-container">
      <pano-modal-title name="版权保护提醒"></pano-modal-title>
      <div class="copyright-tip-body-container">
        <div class="copyright-tip-text">
          为能最大限度保护版权利益，建议为本次上传的素材打上系统水印。
        </div>
        <div class="copyright-tip-a"><a href="">查看水印效果</a></div>
      </div>
      <div class="copyright-tip-footer-container">
        <div class="upload-button-container">
          <pano-button
            name="上传并打水印"
            @click="uploadWithWatermark()"
          ></pano-button>
          <input
            ref="selectWatermark"
            class="upload-button-input"
            @change="selectImage"
            type="file"
            accept="image/jpeg"
          />
        </div>
        <div class="upload-normal-button-container">
          <span>上传但不打水印</span>
        </div>
      </div>
      <pano-circle-close
        class="pano-close"
        @click="close()"
      ></pano-circle-close>
    </div>
  </div>
</template>
<script>
// import { upload } from "api/my/publish.js";
import Util from 'common/js/util'
export default {
  methods: {
    close(o) {
      this.$emit("close",o);
    },
    uploadWithWatermark() {
      this.$refs.selectWatermark.click();
    },
    selectImage() {
      let fs = this.$refs.selectWatermark.files;
      if (fs.length > 0) {
        let flist=[]
        for (let i = 0; i < fs.length; i++) {
          let f = fs[i];
          const reader = new FileReader();
          reader.onloadend = (r) => {
            let base64Data = r.target.result;
            flist.push({
              id:new Date().valueOf(),
              serverId:null,
              name: Util.getFileNameWithoutExtension(f.name),
              fullName: f.name,
              watermark: true,
              base64: base64Data,
              status:0
            })
            if(flist.length==fs.length){
              this.close(flist);
            }
          };
          reader.readAsDataURL(f);
        }
      }
    },
  },
};
</script>
<style lang="scss">
.copyright-tip-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .copyright-tip-wrap-container {
    width: 500px;
    height: 400px;
    background: #ffffff;
    border-radius: 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    .pano-close {
      position: absolute;
      right: -16px;
      top: -16px;
    }
    .copyright-tip-body-container {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .copyright-tip-text,
      .copyright-tip-a {
        text-align: center;
        line-height: $font-size-large-x;
        font-size: $font-size-small-x;
        a {
          color: $color-theme;
        }
      }
      .copyright-tip-text {
        color: $color-text-deep;
      }
    }
    .copyright-tip-footer-container {
      line-height: 60px;
      position: relative;
      border-top: 1px #eeeeee solid;
      text-align: center;
      height: 60px;
      .upload-button-container {
        display: inline-block;
        width: 160px;
        height: 100%;
        position: relative;
        .upload-button-input {
          display: none;
        }
      }
      .upload-normal-button-container {
        position: absolute;
        line-height: 60px;
        top: 0;
        right: 0;
        bottom: 0;
        padding-right: 20px;
        span {
          font-size: $font-size-small;
          color: $color-theme;
          cursor: pointer;
        }
      }
    }
  }
}
</style>