<template>
  <div class="publish-product-container">
    <div class="publish-btn-container">
      <pano-button
        size="medium"
        name="发布作品"
        @click="doPublich()"
      ></pano-button>
    </div>
    <div class="publish-label-container">作品保存到</div>
    <div class="publish-value-container">根目录</div>
    <div class="publish-label-container pano-required">作品标题</div>
    <div class="publish-value-container">
      <pano-input placeholder="请输入作品标题" v-model="projectTitle"></pano-input>
    </div>
    <div class="publish-label-container pano-required">作品分类</div>
    <div class="publish-value-container">
      <pano-input placeholder="请选择作品分类"></pano-input>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
  computed: {
    ...mapState('publishModule', ['title','imgs']),
    projectTitle: {
      get() {
        return this.title;
      },
      set(value) {
        this.setTitle(value);
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions('publishModule', ['setTitle','publish']),
    doPublich() {
      if(this.imgs.some(o=>o.status!=2)){
        alert('请等待素材制作完成')
        return
      }
      const projectInfo={
        name:this.title,
        materials:this.imgs.filter(o=>o.status==2).map(o=>o.serverId)
      }
      console.log(projectInfo)
      console.log(this.imgs)
      this.publish(projectInfo).then(o=>{
        alert('上传成功'+o)
      })
    },
  },
};
</script>
<style lang="scss">
.publish-product-container {
  .publish-btn-container {
    margin-bottom: 20px;
  }
  .publish-label-container {
    padding: 10px 0;
    margin-bottom: 10px;
    color: $color-text;
    font-size: $font-size-medium;
  }
  .publish-value-container {
    margin-bottom: 10px;
  }
}
</style>